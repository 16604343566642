import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import {debounce} from "./utils/etc";
import {preloadNativeAds, resetNativeAdsCounter} from "./utils/native-ads";
import {onLCP} from "web-vitals";
import {gtag} from "./utils/ga";
import TestInstanceToast from "./components/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";

gtag("event", "user_data", {
  custom_split_group: "" + window.clientConfig.splitGroupId,
});

if (window.clientConfig.isWebMobile) {
  onLCP((metric) => {
    logEvent(userEvents.LCP_REPORT, {
      value: metric.value,
      delta: metric.delta,
      rating: metric.rating,
    });
    gtag("event", "lcp_report", {
      value: Math.round(metric.delta),
      non_interaction: true,
      transport: "beacon",
    });
  });
}

window.onerror = function(message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

const LazyIndexPage = React.lazy(() => import("./pages/IndexPage"));
const LazyResultPage = React.lazy(() => import("./pages/result/ResultPage"));
const LazyMaskEditorPage = React.lazy(() => import("./pages/MaskEditorPage"));
const LazyProcessingPage = React.lazy(() => import("./pages/ProcessingPage"));
const LazyUploadPage = React.lazy(() => import("./pages/UploadPage"));
const LazyErrorPage = React.lazy(() => import("./pages/ErrorPage"));

class App extends React.Component {

  componentDidMount() {
    window.nppLoadFont(window.clientConfig.lang);

    resetNativeAdsCounter();

    window.webviewEventsListeners.tabSelected.subscribe((v) => {
      v && this.handleWebviewTabSelected();
    }, true);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();

      setTimeout(() => {
        webviewUtils.webviewCheckInstalledApps((apps) => {
          this.context.setInstalledApps(apps);
        });
      }, 200);
    }

    window.clientConfig.platform.os.toLowerCase() === "ios"
      && document.body.classList.add("ios");

    window.clientConfig.isWebview
      && document.body.classList.add("webview");

    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.classList.add("ui--creative-tabs-v2");

    window.clientConfig.features.showNeuroAvatars && document.body.classList.add("ui--neuroavatars-promo");

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  handleWebviewTabSelected = () => {
    debounce("App.handleWebviewTabSelected.preloadAds", 300, () => {
      preloadNativeAds();
    });
  };

  render() {
    return (
      <div>
        <WindowSizeWatcher />
        <TestInstanceToast />
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          <BrowserRouter>
            <Suspense fallback={<div />}>
              <Switch>
                <Route exact path={routes.INDEX} render={(props) => <LazyIndexPage {...props} />} />
                <Route exact path={routes.UPLOAD} render={(props) => <LazyUploadPage {...props} />} />
                <Route exact path={routes.MASK_EDITOR} render={(props) => <LazyMaskEditorPage {...props} />} />
                <Route exact path={routes.PROCESSING} render={(props) => <LazyProcessingPage {...props} />} />
                <Route exact path={routes.RESULT} render={(props) => <LazyResultPage {...props} />} />
                <Route exact path={routes.ERROR} render={(props) => <LazyErrorPage {...props} />} />
              </Switch>
            </Suspense>
          </BrowserRouter>
          <ModalWrapper />
        </ErrorBoundary>
      </div>
    );
  }
}

App.contextType = AppContext;

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<AppContextProvider><App /></AppContextProvider>);